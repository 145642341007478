<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl">회원가입</h2>
            </div>

            <!-- 단계 -->
            <div class="mb-40 mb-md-60">
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24 white--text">1</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">2</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">3</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                </v-row>
            </div>

            <!-- 약관동의 -->
            <terms-of-agreements ref="terms" :code="$route.query.code" />

            <!-- 하단버튼 -->
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="6" sm="auto">
                        <v-btn large outlined block color="grey-d6" class="w-md-240px" @click="$router.go(-1)">취소</v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn large block color="primary" class="w-md-240px" @click="submit">계속 진행</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";

export default {
    components: {
        ClientPage,
        TermsOfAgreements,
    },
    methods: {
        submit() {
            try {
                if (this.$refs.terms.check()) {
                    this.$store.dispatch("agreements", this.$refs.terms.selected);

                    this.$router.push({
                        path: "/join/form",
                        query: this.$route.query,
                    });
                }
            } catch (error) {
                alert(error.message.toString());
            }
        },
    },
};
</script>
