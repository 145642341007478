var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--join"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('div', {
    staticClass: "join-gate"
  }, [_c('div', {
    staticClass: "join-gate-box join-gate-box--personal"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("개인")]), _c('i', {
    staticClass: "icon icon-chair"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(_vm.PERSON.value);
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)]), _c('div', {
    staticClass: "join-gate-box join-gate-box--business"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("개인/법인사업자")]), _c('i', {
    staticClass: "icon icon-picture"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(_vm.COMPANY.value);
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }