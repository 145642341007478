<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl">회원가입</h2>
            </div>

            <!-- 단계 -->
            <div class="mb-40 mb-md-60">
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">1</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24 white--text">2</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">3</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                </v-row>
            </div>

            <join-form :code="code" :skin="code"></join-form>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import JoinForm from "@/components/client/join/join-form.vue";
import { USER_TYPES } from '@/assets/variables';

export default {
    components: {
        ClientPage,
        JoinForm,
    },
    computed: {
        code() {
            return this.$route.query?.code || USER_TYPES.PERSON.value;
        },
    },
};
</script>
