<template>
    <div>
        <div :class="{'mt-36 mt-md-46' : index != 0}" v-for="terms,index in items" :key="terms._id">
            <div>
                <v-row class="row--x-small">
                    <v-col cols="">
                        <h2 class="font-size-18 font-size-md-20 primary--text">{{ terms.subject }}</h2>
                    </v-col>
                    <v-col cols="auto">
                        <v-checkbox v-model="selected" name="terms" :value="terms._id" label="동의합니다" hide-details class="d-inline-flex"></v-checkbox>
                    </v-col>
                </v-row>
                <v-divider class="border-2 primary mt-6 mt-lg-8"></v-divider>
                <v-card v-if="terms.content" tile outlined flat height="180" class="overflow-auto">
                    <div class="px-16 py-12 px-md-20 py-md-16">
                        <p v-html="terms.content" name="terms" readonly class="font-size-16 font-size-md-18 grey-1e--text"></p>
                    </div>
                </v-card>
            </div>
        </div>

        <!-- 전체동의 -->
        <div class="mt-36 mt-md-46" v-if="items.length > 1">
            <v-row class="row--x-small">
                <v-col cols="">
                    <h2 class="font-size-18 font-size-md-20 primary--text">전체선택</h2>
                </v-col>
                <v-col cols="auto">
                    <v-checkbox :value="termsList.length == selected.length" @click="selected = (termsList.length == selected.length) ? [] : termsList.map((terms) => terms._id);" label="모두 동의합니다" hide-details class="d-inline-flex"></v-checkbox>
                </v-col>
            </v-row>
            <v-divider class="border-2 primary mt-6 mt-lg-8"></v-divider>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: ["code"],
    data(){
        return {
            selected: [],
            termsList: [],

            filter: {
                code: this.$props.code || undefined
            }
        }
    },
    computed:{
        items(){
            return this.termsList.filter(({ subject })=> subject != '공유공간 이용규정');
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { termsList } = await api.v1.terms.gets({ params: this.filter });
                this.termsList = termsList;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check(){
            try{
                this.items.forEach((terms) => {
                    if(terms.necessary && this.selected.indexOf(terms._id) < 0){
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                })

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
    }
}
</script>
