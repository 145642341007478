var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--join"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl"
  }, [_vm._v("회원가입")])]), _c('div', {
    staticClass: "mb-40 mb-md-60"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 40 : 60,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary lighten-4"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-24",
    staticStyle: {
      "color": "#83c8e5"
    }
  }, [_vm._v("1")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "w-20px w-md-60px border-2 primary lighten-4"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 40 : 60,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary lighten-4"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-24",
    staticStyle: {
      "color": "#83c8e5"
    }
  }, [_vm._v("2")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "w-20px w-md-60px border-2 primary lighten-4"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 40 : 60,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-24 white--text"
  }, [_vm._v("3")])])], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "px-30 py-50 py-sm-60 py-md-80 py-lg-100"
  }, [_c('div', {
    staticClass: "mb-20 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "260",
      "src": "/images/logo.svg"
    }
  })], 1), _c('div', {
    staticClass: "tit tit--sm text-center"
  }, [_vm._v("회원가입이 완료 되었습니다")])])]), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary",
      "to": "/"
    }
  }, [_vm._v("완료")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }