<template>
    <client-page class=" wrap--join">
        <div class="container">
            <h2 class="join-title">회원가입</h2>

            <div class="join-container">
                <div class="join-gate">
                    <!-- 개인 -->
                    <div class="join-gate-box join-gate-box--personal">
                        <div class="join-gate-box__inner">
                            <h2>개인</h2>
                            <!-- <p>일반회원에 대한 짧은 설명</p> -->
                            <i class="icon icon-chair"></i>
                            <v-btn @click="submit(PERSON.value)" color="primary">
                                가입하기
                            </v-btn>
                        </div>
                    </div>
                    <!-- 업체 -->
                    <div class="join-gate-box join-gate-box--business">
                        <div class="join-gate-box__inner">
                            <h2>개인/법인사업자</h2>
                            <!-- <p>사업자회원에 대한 짧은 설명</p> -->
                            <i class="icon icon-picture"></i>
                            <v-btn @click="submit(COMPANY.value)" color="primary">
                                가입하기
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- 하단버튼 -->
                <!-- <div class="bottom-button">
                    <a class="button button--border-primary" href="" onclick="return false;" @click="$router.go(-1)"><span>취소하기</span></a>
                    <a class="button button--primary" @click="submit"><span>계속 진행</span></a>
                </div> -->
            </div>
        </div>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import { USER_TYPES } from "@/assets/variables";
const { PERSON, COMPANY } = USER_TYPES;

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        PERSON,
        COMPANY,
    }),
    methods: {
        submit(code) {
            this.$router.push(`/join?code=${code}`);
        },
    },
};
</script>
