<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl">회원가입</h2>
            </div>

            <!-- 단계 -->
            <div class="mb-40 mb-md-60">
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">1</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-4" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24" style="color:#83c8e5;">2</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                    <v-col cols="auto"><v-divider class="w-20px w-md-60px border-2 primary lighten-4" /></v-col>
                    <v-col cols="auto">
                        <v-responsive :width="$vuetify.breakpoint.xs ? 40 : 60" :aspect-ratio="1/1">
                            <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-24 white--text">3</strong></v-sheet>
                        </v-responsive>
                    </v-col>
                </v-row>
            </div>

            <v-card max-width="550" class="ma-auto">
                <div class="px-30 py-50 py-sm-60 py-md-80 py-lg-100">
                    <div class="mb-20 mb-md-24">
                        <v-img max-width="260" class="ma-auto" src="/images/logo.svg"/>
                    </div>
                    <div class="tit tit--sm text-center">회원가입이 완료 되었습니다</div>
                </div>
            </v-card>

            <!-- 하단버튼 -->
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="6" sm="auto">
                        <v-btn large block color="primary" to="/" class="w-md-240px">완료</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default{
	components: {
		ClientPage,
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
